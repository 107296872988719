import React, { useEffect, useState, lazy, Suspense } from "react";
import { motion, useSpring, useTransform, useScroll } from 'framer-motion'
import useTheme from "@mui/system/useTheme";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/system/Box";

import BowListLazy from "../../_shared/shaping/BowListLazy"
import AnimateScrollIntoViewport from "../../_shared/AnimateScrollIntoViewport"
import { useBreakpoint } from "../../_shared/contextBreakpointMediaQ";
import SeparatorAbsSvg2 from "../../_shared/shaping/SeparatorAbsSvg2";
import { POLYGON_RECTANGLE_10_POINTS_LEFT, POLYGON_RECTANGLE_10_POINTS_RIGHT, POLYGON_STAR } from "../../../utils/clipPathsConstants";
import ClientServerAppGeneral from "./ClientServerAppGeneral";

const bLOptionsDouble = {
  itemHeightPx: 120,
  primarySecondarySeparator: "|",
}

const bLOptionsDoubleMd = {
  itemHeightPx: 100,
  primarySecondarySeparator: "|",
}




const bowListL = {
  motionDirection: "left",
  bowDirection: "left",
  bowPosition: "left",
}

const bowListR = {
  motionDirection: "right",
  bowDirection: "right",
  bowPosition: "right",
}

const bowListL2 = {
  motionDirection: "left",
  bowPosition: "left",
  bowDirection: "right",
}

const bowListR2 = {
  motionDirection: "right",
  bowPosition: "left",
  bowDirection: "left",
}




const styleBLBordersBRTL50px = {
  borderBottomRightRadius: "50px",
  borderTopLeftRadius: "50px",
}
const styleBLBordersBLTR50px = {
  borderBottomLeftRadius: "50px",
  borderTopRightRadius: "50px",
}
const styleBLBordersTLBR20 = {
  borderTopLeftRadius: "20%",
  borderBottomRightRadius: "20%",
}
const styleBLBordersTRBL20 = {
  borderTopRightRadius: "20%",
  borderBottomLeftRadius: "20%",
}

const styleSxWrap = {

  display: 'block',
  "& .container": {
    my: 0,
    p: [ 0.5, 1, 2, 3 ],

    "&.contUp": {
      color: "primary.contrastText",
      background: (theme) => theme.gradients.lin3(0, theme.palette.primary2.main, "0%", theme.palette.primary2.light, "50%", theme.palette.primary2.main, "100%",),
      pb: 0,
      py: 0,
    },
    "&.contFeat": {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      my: 0,
      bgcolor: "primary2.main",
      color: "primary2.contrastText",
    },
    "&.contPr": {
      color: "primary.contrastText",
    },
    "&.contInsp": {
      color: "primary2.contrastText",
    },
    "& .wrapTitleBoost": {
      width: "100%",

    },
  },
  "& .gridItem": {
    display: "flex",
    justifyContent: "center"
  },
}






let fontSizeBL = "small"
const listPaddingTopPx = 20
const listPaddingBottomPx = 20
const listMarginTopPx = 0
const listMarginBottomPx = 0

function MainUp(props) {
  const { title } = props
  const breakpoint = useBreakpoint()

  const theme = useTheme()

  const [ isComplete, setIsComplete ] = useState(false);
  const { scrollYProgress } = useScroll();
  const yRange = useTransform(scrollYProgress, [ 0, 0.9 ], [ 0, 1 ]);
  const pathLength = useSpring(yRange, { stiffness: 400, damping: 90 });

  useEffect(() => {
    const unsubscribe = yRange.on("change", (v) => setIsComplete(v >= 1));
    return () => unsubscribe();
  }, [ yRange ]);


  switch (breakpoint) {
    case "xs":
      bLOptionsDoubleMd.itemHeightPx = 40
      bLOptionsDoubleMd.itemsXDistancePx = 20
      bLOptionsDouble.itemHeightPx = 50
      bLOptionsDouble.itemsXDistancePx = 20

      fontSizeBL = "small" //"0.9rem" // "unset" // "1rem"
      break;
    case "sm":
      bLOptionsDoubleMd.itemHeightPx = 30
      bLOptionsDoubleMd.itemsXDistancePx = 20
      bLOptionsDouble.itemHeightPx = 30
      bLOptionsDouble.itemsXDistancePx = 20
      fontSizeBL = "small"

      break;
    case "md":
      bLOptionsDoubleMd.itemHeightPx = 50
      bLOptionsDoubleMd.itemsXDistancePx = 20
      bLOptionsDouble.itemHeightPx = 50
      bLOptionsDouble.itemsXDistancePx = 20
      fontSizeBL = "small"
      break;
    default:
      bLOptionsDoubleMd.itemHeightPx = 40
      bLOptionsDoubleMd.itemsXDistancePx = 20
      bLOptionsDouble.itemHeightPx = 40
      bLOptionsDouble.itemsXDistancePx = 20
      fontSizeBL = "small"
      break;
  }
  const bLOptionsDoubleMdH = { ...bLOptionsDoubleMd }

  const sepFillColor = theme.palette.primary2.dark
  const sepFillColorContrastText = theme.palette.primary2.contrastText

  return (
    <Box component="div"
      sx={styleSxWrap}
    >
      <svg
        className="klientServerress-icon"
        viewBox="0 0 50 50"
        style={{ position: "fixed", top: 90, left: 50, width: 100, zIndex: 1 }}
      >
        <motion.path
          fill="none"
          strokeWidth="2"
          stroke="green"
          strokeDasharray="0 1"
          d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
          style={{
            pathLength,
            rotate: 90,
            translateX: 5,
            translateY: 5,
            scaleX: -1
          }}
          key="mainUp-klientServer-path1"

        />
        <motion.path
          fill="none"
          strokeWidth="2"
          stroke="green"
          d="M14,26 L 22,33 L 35,16"
          initial={false}
          strokeDasharray="0 1"
          animate={{ pathLength: isComplete ? 1 : 0 }}
          key="mainUp-klientServer-path2"
        />
      </svg>

      <SeparatorAbsSvg2
        name="sepNormalRel2FullHorizontalHills"
        id="sepClientServerAppGeneral"
        key="sepClientServerAppGeneral"
        optionsSep={{
          dPathOrderNotIn: [ 1 ],
          styleWrapSep: {
            lineHeight: "inherit"
          },
          styleSvg: {
            height: "150px",
          },
          svgYPadding: 0,
          isRelative: true,
        }} >
        <Container
          maxWidth={false}
          className="container contPr"
          style={{
            borderRadius: 'unset', backgroundColor: sepFillColor,
            color: sepFillColorContrastText
          }}
          key="contClientServerAppGeneral"
        >
          <ClientServerAppGeneral />
        </Container>
      </SeparatorAbsSvg2>
    </Box>
  );
}

export default MainUp




